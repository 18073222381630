import NavBar from "./components/Navbar/NavBar";
import MiniDrawer from "./components/Navbar/newNavBar";

function App() {
  return (
    <>
      <NavBar />
      {/* <MiniDrawer /> */}
    </>
  );
}

export default App;
