import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const ParentGuides = createApi({
  reducerPath: "ParentGuides",
  baseQuery,
  tagTypes: ["parenting", "subParent"],
  endpoints: (builder) => ({
    getParentGuides: builder.query({
      query: ({ search, page }) => ({
        url: `parent-guides`,
        method: "get",
        params: {
          search,
          page,
        },
      }),
      providesTags: ["parenting"],
    }),

    createParentGuides: builder.mutation<any, any>({
      query: (body) => ({
        url: "parent-guides/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["parenting"],
    }),

    getSingleParentGuides: builder.query({
      query: (id) => ({
        url: `parent-guides/show/${id}`,
        method: "get",
      }),
      providesTags: ["parenting"],
    }),

    updateParentGuide: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `parent-guides/update/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["parenting"],
    }),

    deleteParentGuide: builder.mutation<any, any>({
      query: (id) => ({
        url: `parent-guides/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["parenting"],
    }),

    // Sub
    getSingleSubParentGuide: builder.query({
      query: ({ search, page, id }) => ({
        url: `/parent-guides/sub-guides/${id}`,
        method: "get",
        params: {
          search,
          page,
        },
      }),
      providesTags: ["subParent"],
    }),
    createSingleSubParentGuide: builder.mutation<any, any>({
      query: (body) => ({
        url: "parent-guides/sub-guides/store",
        method: "POST",
        body,
      }),
      invalidatesTags: ["subParent"],
    }),
    editSingleSubParentGuide: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `parent-guides/sub-guides/update/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["subParent"],
    }),
    deleteSubParentGuide: builder.mutation<any, any>({
      query: (id) => ({
        url: `parent-guides/sub-guides/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subParent"],
    }),
  }),
});

export const {
  useLazyGetParentGuidesQuery,
  useCreateParentGuidesMutation,
  useGetSingleParentGuidesQuery,
  useDeleteParentGuideMutation,
  useUpdateParentGuideMutation,
  useGetSingleSubParentGuideQuery,
  useCreateSingleSubParentGuideMutation,
  useDeleteSubParentGuideMutation,
  useEditSingleSubParentGuideMutation,
} = ParentGuides;
