import styled from "styled-components";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";

export const SubStyle = styled(Bigsection)`
  .key {
    font-size: 16px;
    color: #255557;
    text-transform: capitalize;
    padding: 1rem;
  }
  .value {
    font-size: 14px;
    color: #255557;
    text-transform: capitalize;
    text-wrap: balance;
    a {
      color: blue;
    }
  }
  .valueDescription {
    display: block;
    /* width: 5%; */
    /* text-wrap: balance; */
    /* display: flex; */
  }
  .headerNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }
`;
