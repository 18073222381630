import React, { useEffect, useState } from "react";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { Link } from "react-router-dom";

import { Grid, Pagination, TextField } from "@mui/material";
import { Bigsection } from "../../../satisfiedCustomers/components/showData/style";
import { useLazyGetColoringActivitesQuery } from "../../../../../store/RTK/coloringActivities";
import {
  DeleteButton,
  NoButton,
} from "../../../../../components/common/Buttons/deleteButton";
import { BsEye } from "react-icons/bs";
import Delete from "../delete";
import Edit from "../edit";

const Show = () => {
  const [getCoupons, { data, isFetching, isLoading }] =
    useLazyGetColoringActivitesQuery();
  const [page, setPage] = React.useState<number>(1);
  const handleChangePage: any = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const [search, setSearch] = useState<any>("");
  const params = {
    page: page,
    search: search,
  };
  const handlChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    const fetchApi = async () => {
      getCoupons(params, true);
    };
    const delayDebounceFn = setTimeout(() => {
      fetchApi();
    }, 550);

    return () => clearTimeout(delayDebounceFn);
  }, [search, page]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = React.useState<any>();

  const select = (feed: any) => {
    setSelected(feed);
    handleOpen();
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const selectEdit = (feed: any) => {
    setSelected(feed);
    handleOpenEdit();
  };
  console.log("dataaaaaaaaaaaaaaaaa", data?.data?.data);
  let totalPages = data?.data?.total_pages;

  const mapping: any = () => {
    return data?.data?.data?.map((e: any) => {
      return (
        <tr key={e?.id}>
          <th>{e?.title_ar}</th>
          <th>{e?.title_en}</th>
          <th>{e?.description_ar}</th>
          <th>{e?.description_en}</th>
          <th>
            <a href={e?.cover} target="_blank">
              <img src={e?.cover}></img>
            </a>
          </th>
          {/* <th>{e?.pdf}</th> */}
          <th>{e?.is_english}</th>
          <td>
            <div className="actions">
              <DeleteButton onClick={() => select(e)}>Delete</DeleteButton>
              <NoButton onClick={() => selectEdit(e)}>Edit</NoButton>
              <Link to={`/home/coloring-actvities/single/${e?.id}`}>
                <NoButton>
                  <RemoveRedEyeIcon />
                </NoButton>
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Bigsection>
      {isFetching || isLoading ? (
        <h1>Loading</h1>
      ) : (
        <Grid container columns={12}>
          <Grid item xs={12}>
            <TextField
              type="search"
              onChange={handlChange}
              label="Search"
              value={search}
            ></TextField>
            <table>
              <tr>
                <th>title_ar</th>
                <th>title_en</th>
                <th>description_ar</th>
                <th>description_en</th>
                <th>Cover</th>
                {/* <th>Pdf</th> */}
                <th>is English</th>
                <th>Actions</th>
              </tr>
              {mapping()}
            </table>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              page={page}
              count={totalPages}
              onChange={handleChangePage}
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <Delete
        open={open}
        handleClose={handleClose}
        singleItem={selected}
        key={selected?.id}
      />
      <Edit
        open={openEdit}
        handleClose={handleCloseEdit}
        singleItem={selected}
        key={selected?.id}
      />
    </Bigsection>
  );
};

export default Show;
