import styled from "styled-components";
type props = {
  open: boolean;
};
export const NavBarStyle = styled.div<props>`
  img {
    width: 65%;
  }
  a {
    color: inherit;
    text-decoration: none;
    li {
      font-weight: 500;
      font-size: 16px;
      svg {
        font-size: 24px;
      }
    }
  }

  .nav {
    height: 100vh;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    /* align-items: flex-start; */
    /* padding: 0 1rem; */
    /* max-width: 100%; */
    ul {
      list-style: none;
    }
    p,
    span {
      font-family: "Inter", sans-serif !important;
      font-weight: 500;
      font-size: 16px;
      /* color: black !important; */
    }

    li {
      /* padding-bottom: 0.7rem; */

      /* div {
        color: #344054;
        border-radius: 6px;
        background-size: 300px 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          fill: red !important;
        }
      } */
      .icon {
        color: red !important;

        #document {
          svg {
            color: red !important;
            path {
              color: red !important;
            }
          }
        }
      }
    }
    .subDiv {
      padding-left: 0.5rem;
    }
    ul {
      li {
        a.active {
          li {
            background: var(
              --Gradiant,
              linear-gradient(135deg, #4e5ae2 0, #9e32a4 54.17%, #d552d8 100%)
            );
            color: white;
            div {
              svg {
                fill: white !important;
                color: white !important;
              }
              span {
                color: white;
                font-family: bold;
              }
            }
          }
        }
      }
    }
  }
  .des {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #667085 !important;
  }
  .header-open {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-close {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .profile-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    filter: none !important;
  }
  && {
    @media (min-width: 100px) {
      .css-2dum1v-MuiDrawer-docked {
        width: 0;
      }
    }
  }
  .css-bpnhhg {
    width: 0;
  }
`;
