import React from "react";
import {
  useAddAssetBundleMutation,
  useUpdateCategoryMutation,
} from "../../../../store/RTK/category";
import { Bigsection } from "../../satisfiedCustomers/components/delete/style";
import {
  Box,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitButton } from "../../../../components/common/Buttons/SubmitButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import StanderInput from "../../../../components/common/standerInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import StanderTextArea from "../../../../components/common/standardTextArea";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "100%",
  overflow: "scroll",
  height: "70%",
  display: "block",
};

type props = {
  open: boolean;
  handleClose: any;
  singleItem: any;
};

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];

const UpdateCategory = ({ open, handleClose, singleItem }: props) => {
  const [single, setSingle] = React.useState<any>();
  console.log("single", single);
  const [updateCategory, { isLoading, isSuccess, isError }] =
    useUpdateCategoryMutation();
  const schema = yup.object({
    title: yup.string().required("title is required"),
    sort_number: yup.string().required("Sort number is required"),
    qr_code: yup.string().required("qr_code is required"),
    description_ar: yup.string().required("description_ar is required"),
    description_en: yup.string().required("description_en is required"),
    targets_description_ar: yup
      .string()
      .required("targets_description_ar is required"),
    targets_description_en: yup
      .string()
      .required("targets_description_en is required"),
    // soon_title: yup.string().when("is_publish", {
    //   is: 0,
    //   then: (schema) => schema.required("soon_title is required"),
    // }),
    // soon_description: yup.string().when("is_publish", {
    //   is: 0,
    //   then: (schema) => schema.required("soon description is required"),
    // }),
  });

  const id = singleItem?.id;
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      is_publish: +true,
    },
  });
  const control = methods.control;
  console.log("methods", methods.formState.errors);
  const onSubmit = async (data: any) => {
    // try {
    console.log("Sned data", data);
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("qr_code", data.qr_code);
    formData.append("description_ar", data.description_ar);
    formData.append("description_en", data.description_en);
    formData.append("targets_description_ar", data.targets_description_ar);
    formData.append("targets_description_en", data.targets_description_en);
    formData.append("soon_title", data.soon_title);
    formData.append("soon_description", data.soon_description);
    formData.append("is_publish", data.is_publish);
    formData.append("is_english", data.is_english);
    formData.append("sort_number", data.sort_number);
    const sendData = {
      id: singleItem?.id,
      data: data,
    };
    await updateCategory(sendData);
    // } catch (error: any) {}
  };
  React.useEffect(() => {
    if (isSuccess) {
      toast.success("Category has been added");
      handleClose();
    }
    if (!isSuccess && isError) {
      toast.error("somthing wrong");
    }
  }, [isSuccess, isError]);
  React.useLayoutEffect(() => {
    methods.setValue("title", singleItem?.title);
    methods.setValue("qr_code", singleItem?.qr_code);
    methods.setValue("description_ar", singleItem?.description_ar);
    methods.setValue("description_en", singleItem?.description_en);
    methods.setValue(
      "targets_description_ar",
      singleItem?.targets_description_ar
    );
    methods.setValue(
      "targets_description_en",
      singleItem?.targets_description_en
    );
    methods.setValue("soon_title", singleItem?.soon_title);
    methods.setValue("soon_description", singleItem?.soon_description);
    methods.setValue("is_publish", singleItem?.is_publish);
    methods.setValue("is_english", singleItem?.is_english);
    methods.setValue("sort_number", singleItem?.sort_number);
  }, [singleItem]);
  const isPublish = methods.watch("is_publish");
  const isEnglish = methods.watch("is_english");
  console.log("isPublish", isPublish);
  return (
    <Bigsection>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Update
          </Typography>

          <div
            className="btns"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              justifyContent: "center",
              padding: "1rem 0",
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container columns={12} spacing={3}>
                  <Grid item xs={12} style={{}}></Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="title"
                      placeholder="title"
                      Label="title"
                      type="string"
                      defaultValue={single?.title}
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="qr_code"
                      placeholder="qr_code"
                      Label="qr_code"
                      type="string"
                      defaultValue={single?.qr_code}
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderInput
                      // required
                      name="sort_number"
                      placeholder="Sort number"
                      Label="Sort number"
                      type="number"
                      defaultValue={single?.sort_number}
                    ></StanderInput>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      // required
                      name="description_ar"
                      placeholder="description_ar"
                      Label="description_ar"
                      type="text"
                      minRows={5}
                      // defaultValue={single?.description_ar}
                    ></StanderTextArea>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      // required
                      name="description_en"
                      placeholder="description_en"
                      Label="description_en"
                      type="text"
                      minRows={5}
                      // defaultValue={single?.description_en}
                    ></StanderTextArea>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      // required
                      name="targets_description_ar"
                      placeholder="targets_description_ar"
                      Label="targets_description_ar"
                      type="text"
                      minRows={5}
                      // defaultValue={single?.targets_description}
                    ></StanderTextArea>
                  </Grid>
                  <Grid item xs={12}>
                    <StanderTextArea
                      // required
                      name="targets_description_en"
                      placeholder="targets_description_en"
                      Label="targets_description_en"
                      type="text"
                      minRows={5}
                      // defaultValue={single?.targets_description}
                    ></StanderTextArea>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label htmlFor="">Is publish</label>
                    <Controller
                      control={control}
                      name={"is_publish"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <Checkbox
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(+event.target.checked);
                            }}
                            defaultChecked={isPublish ? true : false}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <label htmlFor="">Is English</label>
                    <Controller
                      control={control}
                      name={"is_english"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <Checkbox
                            {...field}
                            value={value?.fileName}
                            onChange={(event: any) => {
                              onChange(+event.target.checked);
                            }}
                            defaultChecked={isEnglish ? true : false}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {!isPublish ? (
                    <>
                      <Grid item xs={12} md={5}>
                        <StanderInput
                          // required
                          name="soon_title"
                          placeholder="soon_title"
                          Label="soon_title"
                          type="string"
                          defaultValue=""
                        ></StanderInput>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <StanderTextArea
                          // required
                          name="soon_description"
                          placeholder="soon_description"
                          Label="soon_description"
                          type="text"
                          minRows={5}
                          defaultValue=""
                        ></StanderTextArea>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <SubmitButton type="submit" fullWidth>
                      {isLoading ? "creating" : "Save"}
                    </SubmitButton>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Box>
      </Modal>
    </Bigsection>
  );
};

export default React.memo(UpdateCategory);
