import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import LoginPage from "../pages/login/loginPage";
import ProtectedRoutes from "./ProtectedRoutes";
import { Anonymous } from "./AnimnousRoutes";
import NotFoundPage from "../components/notFoundPage";
import HomePage from "../pages/homePage";
import AccountType from "../pages/homePage/accountType";
import SatisfiedCustomer from "../pages/homePage/satisfiedCustomers/page";
import CreateFeed from "../pages/homePage/satisfiedCustomers/components/create/index";
import ShowData from "../pages/homePage/satisfiedCustomers/components/showData";
import Faqs from "../pages/homePage/Faqs";
import ShowFaq from "../pages/homePage/Faqs/show";
import CreateFaq from "../pages/homePage/Faqs/create";
import CreateCategory from "../pages/homePage/category/createCategory";
import ShowCategoreis from "../pages/homePage/category/showCategoreis";
import Categories from "../pages/homePage/category";
import ShowAllAccountTypes from "../pages/homePage/accountType/components/show";
import CreateAccountType from "../pages/homePage/accountType/components/create";
import EditAccountType from "../pages/homePage/accountType/components/edit";
import EditMedia from "../pages/homePage/accountType/components/editMedia";
import SigleCategory from "../pages/homePage/category/showCategoreis/SigleCategory";
import CreateTarget from "../pages/homePage/category/showCategoreis/SigleCategory/createTarget";
import PaymentMethod from "../pages/paymentMethod";
import AllPayment from "../pages/paymentMethod/components/allPayment";
import Subscription from "../pages/subscription";
import ShowDataSub from "../pages/subscription/showData/ShowData";
import CreateSub from "../pages/subscription/createSub";
import AddDescription from "../pages/subscription/AddDescrip";
import UnityFaq from "../pages/homePage/unityFaqs/page";
import FaqCategory from "../pages/homePage/Faqs/faqCategory";
import CreateCategoryFaq from "../pages/homePage/Faqs/faqCategory/create";
import ShowCategoryFaq from "../pages/homePage/Faqs/faqCategory/show";
import FaqUnity from "../pages/homePage/unityFaqs/page";
import ShowCategory from "../pages/homePage/unityFaqs/components/category/showCategories";
import SingleCategory from "../pages/homePage/unityFaqs/components/category/singleCategory";
import CreateFaqUnity from "../pages/homePage/unityFaqs/components/category/singleCategory/create";
import ShowSinlgeCategory from "../pages/homePage/unityFaqs/components/category/singleCategory/show";
import ShowSingleQuestion from "../pages/homePage/unityFaqs/components/category/singleCategory/showSingleQuestion";
import Nontification from "../pages/homePage/nontification";
import Blogs from "../pages/homePage/blogs";
import CreateBlog from "../pages/homePage/blogs/createBlog";
import AllBlogs from "../pages/homePage/blogs/allBlogs";
import SingleBlog from "../pages/homePage/blogs/singleBlog";
import Coupons from "../pages/coupons";
import ShowCoupons from "../pages/coupons/showDataCoupons/ShowData";
import AddCoupon from "../pages/coupons/addCoupon";
import ColoringActivites from "../pages/homePage/coloringActivites";
import Show from "../pages/homePage/coloringActivites/components/show";
import CreateActivity from "../pages/homePage/coloringActivites/components/create";
import Single from "../pages/homePage/coloringActivites/components/single";
import { lazy } from "react";
import SingleWallet from "../pages/homePage/wallet/components/singleWallet";

const Wallet = lazy(() => import("../pages/homePage/wallet"));
const CategoriesProducts = lazy(() => import("../pages/categoriesProducts"));
const CategoryCreate = lazy(() => import("../pages/categoriesProducts/create"));
const CategoryEdit = lazy(() => import("../pages/categoriesProducts/edit"));
const Products = lazy(() => import("../pages/products/index"));
const ProductCreate = lazy(() => import("../pages/products/create/index"));
const ProductEdit = lazy(() => import("../pages/products/edit/index"));
const SingleProduct = lazy(() => import("../pages/products/single/index"));
const WalletProduct = lazy(() => import("../pages/walletProduct/index"));
const SingleWalletProduct = lazy(
  () => import("../pages/walletProduct/components/singleWallet/index")
);
const Orders = lazy(() => import("../pages/orders/index"));
const SingleOrder = lazy(
  () => import("../pages/orders/components/singleWallet")
);
const DeliveryFees = lazy(() => import("../pages/dealerFees"));
const CreateDeliveryFees = lazy(() => import("../pages/dealerFees/create"));
const UpdateDeliveryFees = lazy(() => import("../pages/dealerFees/edit"));
const SingleDeliveryFees = lazy(() => import("../pages/dealerFees/single"));

const Songs = lazy(() => import("../pages/songs"));
const SongsCreate = lazy(() => import("../pages/songs/create"));

const ParentActivity = lazy(() => import("../pages/homePage/parentGuides"));
const ShowParentActivity = lazy(
  () => import("../pages/homePage/parentGuides/components/show")
);
const CreateParentActivity = lazy(
  () => import("../pages/homePage/parentGuides/components/create")
);
const SingleParentActivity = lazy(
  () => import("../pages/homePage/parentGuides/components/single")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/home",
        element: <App />,
        children: [
          {
            path: "home-page",
            element: <HomePage />,
            children: [
              {
                path: "account-type",
                element: <AccountType></AccountType>,
                children: [
                  {
                    path: "show",
                    element: <ShowAllAccountTypes />,
                  },
                  {
                    path: "create",
                    element: <CreateAccountType />,
                  },
                  {
                    path: "edit/:id",
                    element: <EditAccountType />,
                  },
                  { path: "editMedia/:id", element: <EditMedia /> },
                  {
                    index: true,
                    element: (
                      <Navigate to="/home/home-page/account-type/show"></Navigate>
                    ),
                  },
                ],
              },
              {
                path: "satisfied-customer",
                element: <SatisfiedCustomer></SatisfiedCustomer>,
                children: [
                  {
                    path: "create",
                    element: <CreateFeed />,
                  },
                  {
                    path: "show",
                    element: <ShowData />,
                  },
                ],
              },
              {
                element: <Navigate to="satisfied-customer/show"></Navigate>,
                index: true,
              },
            ],
          },
          {
            path: "faqs",
            element: <FaqCategory></FaqCategory>,
            children: [
              { path: "showCategory", element: <ShowCategoryFaq /> },
              { path: "show/:id", element: <ShowFaq /> },
              { path: "create/:id", element: <CreateFaq /> },
              {
                element: <Navigate to="showCategory" />,
                index: true,
              },
            ],
          },
          {
            path: "cetegory",
            element: <Categories></Categories>,
            children: [
              {
                path: "create",
                element: <CreateCategory></CreateCategory>,
              },
              {
                path: "show",
                element: <ShowCategoreis></ShowCategoreis>,
              },
              {
                element: <Navigate to="show"></Navigate>,
                index: true,
              },
              {
                path: "singleCategory/:id",
                children: [
                  {
                    index: true,
                    element: <SigleCategory />,
                  },
                  {
                    path: "add-target",
                    element: <CreateTarget />,
                  },
                ],
              },
            ],
          },
          {
            path: "payment-method",
            element: <PaymentMethod />,
            children: [
              {
                path: "show",
                element: <AllPayment></AllPayment>,
              },
            ],
          },
          {
            path: "subscription",
            element: <Subscription />,
            children: [
              {
                path: "show",
                element: <ShowDataSub />,
              },
              {
                path: "create",
                element: <CreateSub />,
              },
              {
                path: "add-description/:id",
                element: <AddDescription></AddDescription>,
              },
              {
                index: true,
                element: <Navigate to="show"></Navigate>,
              },
            ],
          },
          {
            path: "faq-unity",
            element: <FaqUnity />,
            children: [
              {
                path: "show",
                element: <ShowCategory />,
              },
              {
                path: "singleCategory/:id",
                element: <SingleCategory />,
                children: [
                  {
                    path: "create",
                    element: <CreateFaqUnity />,
                  },
                  {
                    path: "show",
                    children: [
                      {
                        index: true,
                        element: <ShowSinlgeCategory />,
                      },
                      {
                        path: "show-single/:id",
                        element: <ShowSingleQuestion />,
                      },
                    ],
                  },
                ],
              },
              { index: true, element: <Navigate to="show"></Navigate> },
            ],
          },
          {
            path: "nontification",
            element: <Nontification />,
          },
          {
            path: "blogs",
            element: <Blogs />,
            children: [
              {
                path: "create",
                element: <CreateBlog />,
              },
              {
                path: "showallBlogs",
                element: <AllBlogs />,
              },
              {
                path: "singleBlog/:id",
                element: <SingleBlog />,
              },
              {
                index: true,
                element: <Navigate to="showallBlogs"></Navigate>,
              },
            ],
          },
          {
            path: "coupons",
            element: <Coupons />,
            children: [
              {
                path: "show",
                element: <ShowCoupons />,
              },
              {
                path: "create",
                element: <AddCoupon />,
              },
              {
                index: true,
                element: <Navigate to="show"></Navigate>,
              },
            ],
          },
          {
            path: "coloring-actvities",
            element: <ColoringActivites />,
            children: [
              { path: "show", element: <Show /> },
              { path: "create", element: <CreateActivity /> },
              {
                element: <Navigate to="show"></Navigate>,
                index: true,
              },
              { path: "single/:id", element: <Single /> },
            ],
          },
          {
            path: "wallet",
            children: [
              { index: true, element: <Wallet /> },
              {
                path: ":id",
                element: <SingleWallet></SingleWallet>,
              },
            ],
          },
          {
            path: "CategoriesProducts",
            children: [
              { index: true, element: <CategoriesProducts /> },
              {
                path: ":id",
                element: <SingleWallet></SingleWallet>,
              },
              {
                path: "create",
                element: <CategoryCreate></CategoryCreate>,
              },
              {
                path: "edit/:id",
                element: <CategoryEdit></CategoryEdit>,
              },
            ],
          },
          {
            path: "products",
            children: [
              {
                index: true,
                element: <Products />,
              },
              {
                path: "create",
                element: <ProductCreate />,
              },
              { path: "edit/:id", element: <ProductEdit /> },
              { path: "single/:id", element: <SingleProduct /> },
            ],
          },
          {
            path: "walletProduct",
            children: [
              { index: true, element: <WalletProduct /> },
              {
                path: ":id",
                element: <SingleWalletProduct></SingleWalletProduct>,
              },
            ],
          },
          {
            path: "Orders",
            children: [
              { index: true, element: <Orders /> },
              {
                path: ":id",
                element: <SingleOrder></SingleOrder>,
              },
            ],
          },
          {
            path: "deliveryFees",
            children: [
              {
                index: true,
                element: <DeliveryFees />,
              },
              {
                path: "create",
                element: <CreateDeliveryFees />,
              },
              { path: "edit/:id", element: <UpdateDeliveryFees /> },
              { path: "single/:id", element: <SingleDeliveryFees /> },
            ],
          },
          {
            path: "songs",
            children: [
              {
                index: true,
                element: <Songs />,
              },
              {
                path: "create",
                element: <SongsCreate />,
              },
              // { path: "edit/:id", element: <ProductEdit /> },
              // { path: "single/:id", element: <SingleProduct /> },
            ],
          },
          {
            path: "parents-guides",
            element: <ParentActivity />,
            children: [
              { path: "show", element: <ShowParentActivity /> },
              { path: "create", element: <CreateParentActivity /> },
              {
                element: <Navigate to="show"></Navigate>,
                index: true,
              },
              { path: "single/:id", element: <SingleParentActivity /> },
            ],
          },
        ],
      },
      {
        path: "contact",
        element: <h1>contact</h1>,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        index: true,
        element: <Navigate to="home/home-page/satisfied-customer/show" />,
      },
    ],
  },
  {
    element: <Anonymous></Anonymous>,
    children: [
      {
        path: "/login",
        element: <LoginPage></LoginPage>,
      },
    ],
  },
]);
